import React, { useCallback } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { getFieldProps, inputProps } from "../../utils";
import styled from "styled-components/macro";
import { useFormikContext } from "formik";
import MarkdownEditorV2 from "../../shared/MarkdownEditorV2";

import InfoTooltip from "../../InfoTooltip";
import ImageUpload from "../../ImageUpload";

const CustomTypography = styled(Typography)`
  font-size: 1rem;
  margin-top: 1.5rem;
`;

const BuildMarkdownEditor = ({ formik, placeholder, name, value }) => {
  const onChange = useCallback(
    (value) => {
      formik.setFieldValue(name, value);
      formik.setFieldTouched(name, true);
    },
    [formik, name]
  );

  return (
    <Grid item md={6} xs={12}>
      <MarkdownEditorV2
        error={false}
        label={``}
        placeHolder={placeholder}
        {...getFieldProps(formik, {
          name,
        })}
        value={value}
        onChange={onChange}
      />
    </Grid>
  );
};

const NewsText = () => {
  const formik = useFormikContext();

  const buildTextField = (
    formik,
    placeholder,
    name,
    label,
    md = 5,
    xs = 11,
    required = false
  ) => {
    return (
      <Grid item md={md} xs={xs}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          label={label}
          margin="none"
          placeholder={placeholder}
          required={required}
          variant="outlined"
          {...getFieldProps(formik, {
            name,
          })}
        />
      </Grid>
    );
  };

  const handleImageChange = useCallback(
    ({ imgFieldName, url }) => {
      console.log("url", url);
      formik.setFieldValue(imgFieldName, url);
      formik.setFieldTouched(imgFieldName, true);
    },
    [formik]
  );

  const handleImageReset = useCallback(
    ({ imgFieldName }) => {
      formik.setFieldValue(imgFieldName, null);
      formik.setFieldTouched(imgFieldName, true);
    },
    [formik]
  );

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          In this section you should write down all the information to
          understand what the news is about!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <>
          <Grid item md={12} xs={12}>
            <Grid
              container
              alignItems=""
              spacing={2}
              style={{ flexDirection: "row" }}
            >
              <Grid item md={4} xs={4}>
                <Grid
                  container
                  alignItems=""
                  spacing={2}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <CustomTypography color="textSecondary" variant="h6">
                          Upload a picture before the introduction
                        </CustomTypography>
                      </Grid>
                      <Grid item>
                        <InfoTooltip
                          text="Upload .jpg or .png files. The uploaded picture will be displayed with width as a % of the document body (by default 50%).
                  The picture will be scaled vertically to maintain proportions."
                          title="Image Dimensions"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <ImageUpload
                      buttonProps={{
                        children: "Upload",
                      }}
                      caption={
                        <Typography
                          color="textSecondary"
                          style={{ color: "#BFBFBF" }}
                          variant="body2"
                        >
                          Upload picture before the introduction (.png, .jpg,
                          .jpeg)
                        </Typography>
                      }
                      containerStyles={{ height: 250 }}
                      customPathInfo={{ cmp: formik.values?.cmp }}
                      errorMessage={
                        getFieldProps(formik, { name: "imgbeforeintro" })
                          .helperText
                      }
                      maxSize={1.5 * 1024 * 1024} //1.5MB
                      styles={{ maxHeight: 250, width: "unset" }}
                      value={formik.values.imgbeforeintro}
                      onChange={(obj) =>
                        handleImageChange({
                          ...obj,
                          imgFieldName: "imgbeforeintro",
                        })
                      }
                      onReset={(obj) =>
                        handleImageReset({
                          ...obj,
                          imgFieldName: "imgbeforeintro",
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={2} xs={2}>
                <Grid
                  container
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  style={
                    formik.values.imgbeforeintro
                      ? { height: "100%" }
                      : { paddingTop: 64 }
                  }
                >
                  <Grid item>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label={"Img width in %"}
                      placeholder="e.g.: 50%"
                      variant="outlined"
                      {...getFieldProps(formik, {
                        defaultValue: formik.values.imgbeforeintropercent,
                        name: "imgbeforeintropercent",
                      })}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <CustomTypography color="textSecondary" variant="h6">
              Write a brief introduction! *
            </CustomTypography>
          </Grid>

          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              name={`text_default.summary`}
              placeholder={"Default language"}
              value={formik.values.text_default.summary}
            />
            <BuildMarkdownEditor
              formik={formik}
              name={`text_en.summary`}
              placeholder={"English"}
              value={formik.values.text_en.summary}
            />
          </Grid>
        </>
        <>
          <Grid item md={12} xs={12}>
            <Grid
              container
              alignItems=""
              spacing={2}
              style={{ flexDirection: "row" }}
            >
              <Grid item md={4} xs={4}>
                <Grid
                  container
                  alignItems=""
                  spacing={2}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <CustomTypography color="textSecondary" variant="h6">
                          Upload a picture before the main text
                        </CustomTypography>
                      </Grid>
                      <Grid item>
                        <InfoTooltip
                          text="Upload .jpg or .png files. The uploaded picture will be displayed with width as a % of the document body (by default 50%).
                  The picture will be scaled vertically to maintain proportions."
                          title="Image Dimensions"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <ImageUpload
                      buttonProps={{
                        children: "Upload",
                      }}
                      caption={
                        <Typography
                          color="textSecondary"
                          style={{ color: "#BFBFBF" }}
                          variant="body2"
                        >
                          Upload a picture before the main text (.png, .jpg,
                          .jpeg)
                        </Typography>
                      }
                      containerStyles={{ height: 250 }}
                      customPathInfo={{ cmp: formik.values?.cmp }}
                      errorMessage={
                        getFieldProps(formik, { name: "imgbeforemain" })
                          .helperText
                      }
                      maxSize={1.5 * 1024 * 1024} //1.5MB
                      styles={{ maxHeight: 250, width: "unset" }}
                      value={formik.values.imgbeforemain}
                      onChange={(obj) =>
                        handleImageChange({
                          ...obj,
                          imgFieldName: "imgbeforemain",
                        })
                      }
                      onReset={(obj) =>
                        handleImageReset({
                          ...obj,
                          imgFieldName: "imgbeforemain",
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={2} xs={2}>
                <Grid
                  container
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  style={
                    formik.values.imgbeforemain
                      ? { height: "100%" }
                      : { paddingTop: 64 }
                  }
                >
                  <Grid item>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label={"Img width in %"}
                      placeholder="e.g.: 50%"
                      variant="outlined"
                      {...getFieldProps(formik, {
                        defaultValue: formik.values.imgbeforemainpercent,
                        name: "imgbeforemainpercent",
                      })}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: "2rem" }}>
            <CustomTypography color="textSecondary" variant="h6">
              Write the text of the news! *
            </CustomTypography>
          </Grid>
          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              name={`text_default.text`}
              placeholder={"Default language"}
              value={formik.values.text_default.text}
            />
            <BuildMarkdownEditor
              formik={formik}
              name={`text_en.text`}
              placeholder={"English"}
              value={formik.values.text_en.text}
            />
          </Grid>
        </>
        <Grid item md={12} xs={12}>
          <Grid
            container
            alignItems=""
            spacing={2}
            style={{ flexDirection: "row" }}
          >
            <Grid item md={4} xs={4}>
              <Grid
                container
                alignItems=""
                spacing={2}
                style={{ flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <CustomTypography color="textSecondary" variant="h6">
                        Upload a picture at the end of the news
                      </CustomTypography>
                    </Grid>
                    <Grid item>
                      <InfoTooltip
                        text="Upload .jpg or .png files. The uploaded picture will be displayed with width as a % of the document body (by default 50%).
                  The picture will be scaled vertically to maintain proportions."
                        title="Image Dimensions"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                  <ImageUpload
                    buttonProps={{
                      children: "Upload",
                    }}
                    caption={
                      <Typography
                        color="textSecondary"
                        style={{ color: "#BFBFBF" }}
                        variant="body2"
                      >
                        Upload a picture at the end of the news (.png, .jpg,
                        .jpeg)
                      </Typography>
                    }
                    containerStyles={{ height: 250 }}
                    customPathInfo={{ cmp: formik.values?.cmp }}
                    errorMessage={
                      getFieldProps(formik, { name: "imgendofnews" }).helperText
                    }
                    maxSize={1.5 * 1024 * 1024} //1.5MB
                    styles={{ maxHeight: 250, width: "unset" }}
                    value={formik.values.imgendofnews}
                    onChange={(obj) =>
                      handleImageChange({
                        ...obj,
                        imgFieldName: "imgendofnews",
                      })
                    }
                    onReset={(obj) =>
                      handleImageReset({
                        ...obj,
                        imgFieldName: "imgendofnews",
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} xs={2}>
              <Grid
                container
                alignItems="center"
                direction="row"
                justifyContent="center"
                style={
                  formik.values.imgendofnews
                    ? { height: "100%" }
                    : { paddingTop: 64 }
                }
              >
                <Grid item>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label={"Img width in %"}
                    placeholder="e.g.: 50%"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      defaultValue: formik.values.imgendofnewspercent,
                      name: "imgendofnewspercent",
                    })}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginBottom: "0.5rem", marginTop: "2rem" }}>
          <Typography
            color="textSecondary"
            style={{ fontSize: "1rem" }}
            variant="h6"
          >
            Want to upload a video? Upload it here!
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {buildTextField(
            formik,
            "Upload single benefit support video (link)",
            `text_default.video`,
            "Default language",
            6,
            12
          )}
          {buildTextField(
            formik,
            " Upload single benefit support video (link)",
            `text_en.video`,
            "English",
            6,
            12
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewsText;
