import { useHistory, useLocation, useParams } from "react-router-dom";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import slugify from "slugify";
import format from "date-fns/format";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";

import AlertWithShowMore from "../AlertWithShowMore";
import { Link as LinkRRD } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// import GetAppIcon from "@material-ui/icons/Edit";

import { useAppContext } from "../../api/AppContext";
import Loader from "../shared/Loader";
import Timestamps from "../Timestamps";

import { getFieldProps, inputProps } from "../utils";
import LocaleSelect from "../shared/LocaleSelect";
import { getLocaleMapCopy } from "../shared/LocaleSelect";
import JourneysSelect from "../shared/JourneysSelect";
import ImageUpload from "../ImageUpload";
import FileUpload from "../shared/FileUpload";
import {
  createJourneySemantic as createJourneySemanticApi,
  deleteJourneySemanticsByFlow as deleteJourneySemanticsByFlowApi,
  uploadFiles,
  deleteFiles,
} from "../../api";
import useLoading from "../../useLoading";
import AsyncButton from "../shared/AsyncButton";
import OutlinedButton from "../OutlinedButton";
import Preview from "../Preview";
import SaveButtons from "../SaveButtons";
import {
  CHANNEL_BROWSERPLUGIN,
  CHANNEL_CLIENT,
  CHANNEL_EMBEDDED,
  CHANNEL_TEAMSAPP,
  DASHBOARD_ACCESS_ICON,
  DASHBOARD_ACCESS_INSIDE_APP,
} from "./consts";
import InfoTooltip from "../InfoTooltip";

const localeMap = getLocaleMapCopy();

const homePageModeRadioOptions = [
  {
    description:
      "This HTML will be shown to all DIDs associated to this CID without an active journey AND without any available journeys",
    value: "default",
  },
  {
    description:
      "This HTML will be shown to all DIDs associated to this CID, regardless their current journey and their available journeys.",
    value: "force",
  },
  {
    description:
      "This HTML will be shown to all DIDs associated to this CID with current active journey:",
    value: "forceForJourneyIds",
  },
];

const ConfigurationPage = ({ journeys, formik }) => {
  const [homePageLocale, setHomePageLocale] = useState("default"); // just for the homePage upload dropdown

  const handleAvailableJourneysChange = useCallback(
    (value) => {
      formik.setFieldValue("availableJourneys", value);
      formik.setFieldTouched("availableJourneys", true);
    },
    [formik]
  );

  const id = useMemo(() => uuidv4().slice(0, 8), []);

  const handleNameChange = useCallback(
    (e) => {
      formik.setFieldValue("name", e.target.value);
      formik.setFieldTouched("name", true);

      const cid = `${slugify(e.target.value, {
        lower: true,
        replacement: "",
      })}${id}`;

      if (
        !formik.values.isNew ||
        (formik.touched.cid && formik.values.cid !== cid)
      ) {
        return;
      }

      formik.setFieldValue("cid", cid);
    },
    [formik, id]
  );

  const handleCurrentJourneyChange = useCallback(
    (value) => {
      formik.setFieldValue("currentJourney", value);
      formik.setFieldTouched("currentJourney", true);
    },
    [formik]
  );

  const handleForceForJourneyIdsChange = useCallback(
    (value) => {
      formik.setFieldValue("homePageSetting.forceForJourneyIds", value);
      formik.setFieldTouched("homePageSetting.forceForJourneyIds", true);
    },
    [formik]
  );

  const { isLoading: isFileUploading, handler: handleHTMZipFileUpload } =
    useLoading(
      async (args) => {
        const customPathInfo = {
          cid: formik.values.cid,
        };
        const { url } = await uploadFiles({ ...args, customPathInfo });

        // homePage for default; homePageLocalized{"it-IT": , etc for the others
        formik.setFieldValue(
          homePageLocale && homePageLocale !== "default"
            ? "homePageLocalized." + homePageLocale
            : "homePage",
          url
        );
        formik.setFieldTouched(
          homePageLocale && homePageLocale !== "default"
            ? "homePageLocalized"
            : "homePage",
          true
        );
      },
      { throwError: true }
    );

  const { enqueueSnackbar } = useSnackbar();

  const handleFileDelete = useCallback(async () => {
    try {
      const customPathInfo = {
        cid: formik.values.cid,
      };
      // delete home page
      await deleteFiles({
        // "mode" options:
        // - "specificVersion" (just that specific version)
        // - "all" (specificVersion plus attempt at deleting all versions for that language)
        customPathInfo,
        mode: "specificVersion",
        toBeDeletedUrl:
          homePageLocale && homePageLocale !== "default"
            ? formik.values.homePageLocalized[homePageLocale]
            : formik.values["homePage"],
      });
      const fieldName =
        homePageLocale && homePageLocale !== "default"
          ? `homePageLocalized.${homePageLocale}`
          : "homePage";
      formik.setFieldValue(fieldName, null);
      formik.setFieldTouched(fieldName, true);
    } catch (e) {
      enqueueSnackbar("Delete home page error", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, formik, homePageLocale]);

  const handleHTMZipUpload = useCallback(
    async (args) => {
      try {
        await handleHTMZipFileUpload(args);
      } catch (e) {
        enqueueSnackbar(`Upload zip error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, handleHTMZipFileUpload]
  );

  const handleWelcomeMessageEdit = useCallback(async () => {
    let { id, flowId } = formik.values.welcomeMessageSemantic ?? {};
    if (!id) {
      id = `sem-prime-${uuidv4()}`;
      flowId = `flow-${uuidv4()}`;

      const prime = {
        flowId,
        id,
        isFirstInFlow: true,
        ctaType: "CTA",
        cta1Type: "Close",
        cta1Text: "Hello",
        title: "Welcome tip title",
        text: "Welcome tip text",
        type: "PRIME",
        journeyId: "welcome-message",
        // Don't progress and update last interaction on
        tags: "DONT_ADVANCE_JOURNEY",
        stimulusId: "S_P999",
      };
      try {
        await createJourneySemanticApi(prime);
        formik.setFieldValue("welcomeMessageSemantic", {
          id,
          flowId,
        });
        formik.setFieldTouched("welcomeMessageSemantic", true);
      } catch (e) {
        enqueueSnackbar("Create semantic error", {
          variant: "error",
        });
      }
    }

    window.open(`/journeys/welcome-message/en-GB/primes/${id}`, "_blank");
  }, [enqueueSnackbar, formik]);

  const handleWelcomeMessageRemove = useCallback(async () => {
    try {
      await deleteJourneySemanticsByFlowApi({
        flowId: formik.values.welcomeMessageSemantic.flowId,
        journeyId: "welcome-message",
      });
      formik.setFieldValue("welcomeMessageSemantic", null);
      formik.setFieldTouched("welcomeMessageSemantic", true);
    } catch (e) {
      enqueueSnackbar("Delete semantic error", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, formik]);

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Timestamps
            createdAt={formik.values.createdAt}
            createdBy={formik.values.createdBy}
            updatedAt={formik.values.updatedAt}
            updatedBy={formik.values.updatedBy}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{formik.values.cid}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Company name"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "name" })}
                onChange={handleNameChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="CID"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, {
                  disabled: !formik.values.isNew,
                  name: "cid",
                })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                select
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Channel"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "channel" })}
              >
                <MenuItem value={CHANNEL_CLIENT.value}>
                  {CHANNEL_CLIENT.name}
                </MenuItem>
                <MenuItem value={CHANNEL_TEAMSAPP.value}>
                  {CHANNEL_TEAMSAPP.name}
                </MenuItem>
                <MenuItem value={CHANNEL_EMBEDDED.value}>
                  {CHANNEL_EMBEDDED.name}
                </MenuItem>
                <MenuItem value={CHANNEL_BROWSERPLUGIN.value}>
                  {CHANNEL_BROWSERPLUGIN.name}
                </MenuItem>
              </TextField>
            </Grid>

            {formik.values?.channel === CHANNEL_EMBEDDED.value && (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  required
                  select
                  InputLabelProps={{ shrink: true }}
                  InputProps={inputProps}
                  label="How to access the dashboard"
                  margin="none"
                  variant="outlined"
                  {...getFieldProps(formik, { name: "dashboardAccess" })}
                >
                  <MenuItem value={DASHBOARD_ACCESS_ICON.value}>
                    {DASHBOARD_ACCESS_ICON.name}
                  </MenuItem>
                  <MenuItem value={DASHBOARD_ACCESS_INSIDE_APP.value}>
                    {DASHBOARD_ACCESS_INSIDE_APP.name}
                  </MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item md={6} xs={6}>
              <LocaleSelect
                allowEmpty
                label="Default language"
                {...getFieldProps(formik, {
                  name: "locale",
                })}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <JourneysSelect
                multiple
                {...getFieldProps(formik, {
                  name: "availableJourneys",
                })}
                journeys={journeys}
                label="Available Journeys"
                onChange={handleAvailableJourneysChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Leaf version"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "leafVersion" })}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isEnabled}
                    color="primary"
                    name="isEnabled"
                    onChange={formik.handleChange}
                  />
                }
                label="Automated Journey activation"
              />

              {formik.values.isEnabled && formik.values.enabledAt && (
                <Typography variant="body2">
                  Activated on{" "}
                  {format(
                    formik.values.enabledAt * 1000,
                    "dd MMMM yyyy HH:mm:ss"
                  )}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} xs={6}>
              {formik.values.isEnabled && (
                <JourneysSelect
                  {...getFieldProps(formik, {
                    name: "currentJourney",
                  })}
                  journeys={journeys}
                  label="Journey"
                  onChange={handleCurrentJourneyChange}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid item>
            <TextField
              {...getFieldProps(formik, {
                name: "dashHeaderTitle",
              })}
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Dashboard header title"
              variant="outlined"
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        {formik.values.channel === CHANNEL_TEAMSAPP.value && (
          <Grid item xs={12}>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isCopilotGo}
                    color="primary"
                    name="isCopilotGo"
                    onChange={formik.handleChange}
                  />
                }
                label="is Copilot Go bot"
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.canSetInteractionTime}
                  color="primary"
                  name="canSetInteractionTime"
                  onChange={formik.handleChange}
                />
              }
              label="Let user decide favourite interaction time"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.allowSilenceHi}
                  color="primary"
                  name="allowSilenceHi"
                  onChange={formik.handleChange}
                />
              }
              label="Let user silence hi"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.trackComputerName}
                  color="primary"
                  name="trackComputerName"
                  onChange={formik.handleChange}
                />
              }
              label='Track "Computer name" for each user'
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">User Dashboard customizations</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.skipOnboarding}
                    color="primary"
                    name="skipOnboarding"
                    onChange={formik.handleChange}
                  />
                }
                label="Skip the onboarding flow in the dashboard"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.displayJourneyProgress}
                    color="primary"
                    name="displayJourneyProgress"
                    onChange={formik.handleChange}
                  />
                }
                label="Display journey progress"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.displayHiTour}
                    color="primary"
                    name="displayHiTour"
                    onChange={formik.handleChange}
                  />
                }
                label="Display hi tour"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.displayDairyTab}
                    color="primary"
                    name="displayDairyTab"
                    onChange={formik.handleChange}
                  />
                }
                label="Display diary tab"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.displayJourneySelectionTab}
                    color="primary"
                    name="displayJourneySelectionTab"
                    onChange={formik.handleChange}
                  />
                }
                label="Display journey selection tab"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.displayPromptOfFameTab}
                    color="primary"
                    name="displayPromptOfFameTab"
                    onChange={formik.handleChange}
                  />
                }
                label="Display Prompt of fame tab"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.udHoneycomb}
                    color="primary"
                    name="udHoneycomb"
                    onChange={formik.handleChange}
                  />
                }
                label="Home page UI : honeycomb version"
              />
            </Grid>
            {/* ////////////// Start of first extra tab */}
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.extraTab}
                    color="primary"
                    name="extraTab"
                    onChange={formik.handleChange}
                  />
                }
                label="Additional Page menu"
              />
            </Grid>
            <Grid container spacing={2}>
              {formik.values.extraTab && (
                <>
                  <Grid item>
                    <TextField
                      {...getFieldProps(formik, {
                        name: "extraTabProps.title",
                      })}
                      InputLabelProps={{ shrink: true }}
                      InputProps={inputProps}
                      label="Title"
                      variant="outlined"
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      {...getFieldProps(formik, {
                        name: "extraTabProps.url",
                      })}
                      InputLabelProps={{ shrink: true }}
                      InputProps={inputProps}
                      label="Url"
                      placeholder="https://"
                      variant="outlined"
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-1.25rem",
                    }}
                  >
                    <Typography color="textSecondary" variant="caption">
                      Icon
                    </Typography>
                    <ImageUpload
                      styles={{ width: "10%" }}
                      {...getFieldProps(formik, {
                        name: "extraTabProps.icon",
                      })}
                      customPathInfo={{ cid: formik.values.cid }}
                      onChange={({ url }) => {
                        formik.setFieldValue("extraTabProps.icon", url);
                        formik.setFieldTouched("extraTabProps.icon", true);
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {/* ////////////// Start of second extra tab */}
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.extraTab2}
                    color="primary"
                    name="extraTab2"
                    onChange={formik.handleChange}
                  />
                }
                label="Second Additional Page menu"
              />
            </Grid>
            <Grid container spacing={2}>
              {formik.values.extraTab2 && (
                <>
                  <Grid item>
                    <TextField
                      {...getFieldProps(formik, {
                        name: "extraTab2Props.title",
                      })}
                      InputLabelProps={{ shrink: true }}
                      InputProps={inputProps}
                      label="Title"
                      variant="outlined"
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      {...getFieldProps(formik, {
                        name: "extraTab2Props.url",
                      })}
                      InputLabelProps={{ shrink: true }}
                      InputProps={inputProps}
                      label="Url"
                      placeholder="https://"
                      variant="outlined"
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-1.25rem",
                    }}
                  >
                    <Typography color="textSecondary" variant="caption">
                      Icon
                    </Typography>
                    <ImageUpload
                      styles={{ width: "10%" }}
                      {...getFieldProps(formik, {
                        name: "extraTab2Props.icon",
                      })}
                      customPathInfo={{ cid: formik.values.cid }}
                      onChange={({ url }) => {
                        formik.setFieldValue("extraTab2Props.icon", url);
                        formik.setFieldTouched("extraTab2Props.icon", true);
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.displayHome}
                    color="primary"
                    name="displayHome"
                    onChange={formik.handleChange}
                  />
                }
                label="Display home"
              />
            </Grid>
            {formik.values?.channel === CHANNEL_EMBEDDED.value &&
              formik.values?.dashboardAccess ===
                DASHBOARD_ACCESS_ICON.value && (
                <Grid item md={3} xs={12}>
                  <Grid
                    container
                    spacing={1}
                    style={{ flexDirection: "column" }}
                  >
                    <Grid item>
                      <Typography color="textSecondary" variant="caption">
                        Icon
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ImageUpload
                        styles={{ maxHeight: "unset", width: 48 }}
                        {...getFieldProps(formik, { name: "hiWebBtnIcon" })}
                        customPathInfo={{ cid: formik.values.cid }}
                        onChange={({ url }) => {
                          formik.setFieldValue("hiWebBtnIcon", url);
                          formik.setFieldTouched("hiWebBtnIcon", true);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            <Grid item md={3} xs={12}>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item>
                  <Typography color="textSecondary" variant="caption">
                    Logo
                  </Typography>
                </Grid>
                <Grid item>
                  <ImageUpload
                    styles={{ maxHeight: "unset", width: 48 }}
                    {...getFieldProps(formik, { name: "logo" })}
                    customPathInfo={{ cid: formik.values.cid }}
                    onChange={({ url }) => {
                      formik.setFieldValue("logo", url);
                      formik.setFieldTouched("logo", true);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item style={{ display: "flex" }}>
                  <Typography
                    color="textSecondary"
                    style={{ padding: "0px 4px 0px 0px" }}
                    variant="caption"
                  >
                    Header diary tab
                  </Typography>
                  <InfoTooltip
                    style={{ padding: "0px 0px 0px 4px" }}
                    text="Upload .jpg or .png files. The uploaded picture will be displayed with 960px width and 320px height.
                  In case of size mismatch, the picture will be scaled horizontally to 960px and cropped vertically (a central stripe of 320px height will be taken)"
                    title="Image Dimensions"
                  />
                </Grid>
                <Grid item>
                  <ImageUpload
                    styles={{ width: "100%" }}
                    {...getFieldProps(formik, { name: "headerDairyTabImage" })}
                    customPathInfo={{ cid: formik.values.cid }}
                    onChange={({ url }) => {
                      formik.setFieldValue("headerDairyTabImage", url);
                      formik.setFieldTouched("headerDairyTabImage", true);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item style={{ display: "flex" }}>
                  <Typography
                    color="textSecondary"
                    style={{ padding: "0px 4px 0px 0px" }}
                    variant="caption"
                  >
                    Header journeys tab
                  </Typography>
                  <InfoTooltip
                    style={{ padding: "0px 0px 0px 4px" }}
                    text="Upload .jpg or .png files. The uploaded picture will be displayed with 960px width and 320px height.
                  In case of size mismatch, the picture will be scaled horizontally to 960px and cropped vertically (a central stripe of 320px height will be taken)"
                    title="Image Dimensions"
                  />
                </Grid>
                <Grid item>
                  <ImageUpload
                    styles={{ width: "100%" }}
                    {...getFieldProps(formik, {
                      name: "headerJourneysTabImage",
                    })}
                    customPathInfo={{ cid: formik.values.cid }}
                    onChange={({ url }) => {
                      formik.setFieldValue("headerJourneysTabImage", url);
                      formik.setFieldTouched("headerJourneysTabImage", true);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item style={{ display: "flex" }}>
                  <Typography
                    color="textSecondary"
                    style={{ padding: "0px 4px 0px 0px" }}
                    variant="caption"
                  >
                    Header Prompt of Fame tab
                  </Typography>
                  <InfoTooltip
                    style={{ padding: "0px 0px 0px 4px" }}
                    text="Upload .jpg or .png files. The uploaded picture will be displayed with 960px width and 320px height.
                  In case of size mismatch, the picture will be scaled horizontally to 960px and cropped vertically (a central stripe of 320px height will be taken)"
                    title="Image Dimensions"
                  />
                </Grid>
                <Grid item>
                  <ImageUpload
                    styles={{ width: "100%" }}
                    {...getFieldProps(formik, {
                      name: "headerPromptOfFameTabImage",
                    })}
                    customPathInfo={{ cid: formik.values.cid }}
                    onChange={({ url }) => {
                      formik.setFieldValue("headerPromptOfFameTabImage", url);
                      formik.setFieldTouched(
                        "headerPromptOfFameTabImage",
                        true
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item>
                  <Typography color="textSecondary" variant="caption">
                    Home pages
                  </Typography>
                </Grid>

                <Grid item>
                  <FormControl>
                    <RadioGroup
                      name="homePageSetting.mode"
                      value={formik.values.homePageSetting["mode"]}
                      onChange={formik.handleChange}
                    >
                      {homePageModeRadioOptions.map(
                        ({ description, value }) => (
                          <Fragment key={value}>
                            <FormControlLabel
                              control={<Radio color="primary" />}
                              label={
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  {description}
                                </Typography>
                              }
                              value={value}
                            />
                          </Fragment>
                        )
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item>
                  {formik.values?.homePageSetting["mode"] ===
                    "forceForJourneyIds" && (
                    <JourneysSelect
                      {...getFieldProps(formik, {
                        name: "homePageSetting.forceForJourneyIds",
                      })}
                      multiple
                      required
                      journeys={journeys}
                      label="Journeys"
                      onChange={handleForceForJourneyIdsChange}
                    />
                  )}
                </Grid>
                <Box pb={"0.5em"} pt={"0.5em"}>
                  <Divider></Divider>
                </Box>
                <Grid item>
                  <LocaleSelect
                    addOptions={{ default: "Default" }}
                    defaultValue="default"
                    label="Kind"
                    name="homePageLocale"
                    onChange={(e) => setHomePageLocale(e.target.value)}
                  />
                </Grid>
                {homePageLocale === "default" && (
                  <Grid item>
                    <Typography color="textSecondary" variant="body2">
                      This HTML will be shown regardless user’s language, only
                      if there is no custom Home uploaded for user’s specific
                      language.
                    </Typography>
                  </Grid>
                )}
                {(
                  homePageLocale && homePageLocale !== "default"
                    ? formik.values.homePageLocalized[homePageLocale]
                    : formik.values["homePage"]
                ) ? (
                  <Grid item>
                    <Typography color="textSecondary" variant="body2">
                      {homePageLocale === "default"
                        ? "Default"
                        : localeMap[homePageLocale]}{" "}
                      Custom Home page already set.
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography color="textSecondary" variant="body2">
                      {homePageLocale === "default"
                        ? "Default"
                        : localeMap[homePageLocale]}{" "}
                      Custom Home page not set.
                    </Typography>
                  </Grid>
                )}

                <Grid item>
                  <FileUpload accept=".zip" onChange={handleHTMZipUpload}>
                    {({ upload, error }) => (
                      <Grid container alignItems="center" spacing={1}>
                        {(
                          homePageLocale && homePageLocale !== "default"
                            ? formik.values.homePageLocalized[homePageLocale]
                            : formik.values["homePage"]
                        ) ? (
                          <>
                            <Grid item>
                              <Typography variant="body2">
                                <Tooltip title="(Preview)">
                                  <Link
                                    href={
                                      homePageLocale &&
                                      homePageLocale !== "default"
                                        ? formik.values.homePageLocalized[
                                            homePageLocale
                                          ]
                                        : formik.values["homePage"]
                                    }
                                    rel="noreferrer noreferrer"
                                    target="_blank"
                                  >
                                    Open it
                                  </Link>
                                </Tooltip>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <OutlinedButton
                                color="primary"
                                disabled={isFileUploading}
                                endIcon={<EditIcon />}
                                startIcon={
                                  isFileUploading && (
                                    <CircularProgress size={25} />
                                  )
                                }
                                variant="contained"
                                onClick={upload}
                              >
                                Upload new zip
                              </OutlinedButton>
                            </Grid>
                            <Grid item>
                              <OutlinedButton
                                color="primary"
                                disabled={isFileUploading}
                                endIcon={<DeleteIcon />}
                                variant="contained"
                                onClick={handleFileDelete}
                              >
                                Remove
                              </OutlinedButton>
                            </Grid>
                          </>
                        ) : (
                          <Grid item>
                            <Button
                              color="primary"
                              disabled={isFileUploading}
                              endIcon={
                                isFileUploading && (
                                  <CircularProgress size={25} />
                                )
                              }
                              startIcon={<AddIcon />}
                              variant="contained"
                              onClick={upload}
                            >
                              Upload zip
                            </Button>
                          </Grid>
                        )}
                        {error && (
                          <Grid item>
                            <Typography color="error" variant="caption">
                              {error}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </FileUpload>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item>
                  <Typography color="textSecondary" variant="caption">
                    Primary color
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <input
                        type="color"
                        {...getFieldProps(formik, { name: "colors.primary" })}
                        value={
                          getFieldProps(formik, { name: "colors.primary" })
                            .value || "#E6444E"
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {formik.values.colors.primary}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item>
                  <Typography color="textSecondary" variant="caption">
                    Secondary color
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <input
                        type="color"
                        {...getFieldProps(formik, { name: "colors.secondary" })}
                        value={
                          getFieldProps(formik, { name: "colors.secondary" })
                            .value || "#33A6A5"
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {formik.values.colors.secondary}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}></Grid>
            <Grid item md={3} xs={12}>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item>
                  <Typography color="textSecondary" variant="caption">
                    Welcome message
                  </Typography>
                </Grid>
                {!formik.values.welcomeMessageSemantic?.id && (
                  <Grid item>
                    <Grid
                      container
                      spacing={1}
                      style={{ flexDirection: "column" }}
                    >
                      <Grid item>
                        {formik.values?.channel === "teams-app" && (
                          <AlertWithShowMore severity="warning">
                            {({ showMore, isShowMore }) => (
                              <>
                                Default message in use.{" "}
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={showMore}
                                >
                                  {isShowMore ? "Hide" : "Discover more"}
                                </Link>
                                {isShowMore && (
                                  <Box pt={3}>
                                    Teams-app requires a welcome message. The
                                    following is the default:
                                    <br></br>
                                    <LinkRRD
                                      to={`/journeys/e8b1a391-104a-4795-a133-0ffa17b4b76a/it-IT`}
                                    >
                                      welcome_IT
                                    </LinkRRD>{" "}
                                    (if locale is Italian)
                                    <br></br>
                                    <LinkRRD
                                      to={`/journeys/1a34a4b3-1140-4670-9e4e-33394ed383a8/fr-FR`}
                                    >
                                      welcome_FR
                                    </LinkRRD>{" "}
                                    (if locale is French)
                                    <br></br>
                                    <LinkRRD
                                      to={`/journeys/21954562-2294-4b55-a5e6-d7af7fa373a4/en-GB`}
                                    >
                                      welcome_EN
                                    </LinkRRD>{" "}
                                    (in English otherwise)
                                  </Box>
                                )}
                              </>
                            )}
                          </AlertWithShowMore>
                        )}
                      </Grid>
                      <Grid item>
                        <AsyncButton
                          color="primary"
                          startIcon={<AddIcon />}
                          variant="contained"
                          onClick={handleWelcomeMessageEdit}
                        >
                          Add
                        </AsyncButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {formik.values.welcomeMessageSemantic && (
                  <Grid item>
                    <Preview
                      semanticId={formik.values.welcomeMessageSemantic.id}
                      src={`${
                        process.env
                          .REACT_APP_account_content_dashboard_render_url
                      }?silent=true&preview=true&props=${btoa(
                        JSON.stringify({
                          did: `preview_en-GB`,
                          semanticId: formik.values.welcomeMessageSemantic.id,
                        })
                      )}`}
                      userId="preview_en-GB"
                    >
                      {({ iframe, reload, width, height }) => (
                        <>
                          <Box
                            height={height}
                            style={{ overflowY: "auto" }}
                            width={width + 15}
                          >
                            {iframe}
                          </Box>
                          {/* <Grid> */}
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="center"
                            mt={3}
                            width={width + 15}
                          >
                            <Box mb={2} mr={2}>
                              <OutlinedButton
                                endIcon={<EditIcon />}
                                size="small"
                                onClick={handleWelcomeMessageEdit}
                              >
                                Edit
                              </OutlinedButton>
                            </Box>
                            <Box mb={2} mr={2}>
                              <OutlinedButton
                                endIcon={<DeleteIcon />}
                                size="small"
                                onClick={handleWelcomeMessageRemove}
                              >
                                Remove
                              </OutlinedButton>
                            </Box>
                            <Box>
                              <OutlinedButton
                                endIcon={<AutorenewIcon />}
                                size="small"
                                onClick={reload}
                              >
                                Reset
                              </OutlinedButton>
                            </Box>
                            {/* <Box ml={2}>
                                <OutlinedButton
                                  endIcon={<GetAppIcon />}
                                  size="small"
                                  onClick={openExportImage}
                                >
                                  Export
                                </OutlinedButton>
                              </Box> */}
                          </Box>
                          {/* </Grid> */}
                        </>
                      )}
                    </Preview>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SaveButtons formik={formik} />
        </Grid>
      </Grid>
    </Box>
  );
};

const editSchema = yup.object({
  name: yup.string().required().min(5),
  cid: yup
    .string()
    .required()
    .test(
      "cid",
      "CID should be at least 8 characters length",
      (value) => value && (value === "demo" || value.trim().length >= 8)
    ),
  availableJourneys: yup.array().of(yup.string().min(1)),
  currentJourney: yup.string().when("isEnabled", {
    is: (isEnabled) => isEnabled,
    then: yup.string().nullable().required(),
    otherwise: yup.string().nullable(),
  }),
  homePageSetting: yup.object().shape({
    mode: yup.string().required(),
    forceForJourneyIds: yup.array().when("mode", {
      is: "forceForJourneyIds",
      then: yup
        .array()
        .required("Home pages Journey selection is required")
        .of(yup.string())
        .min(1, "Select at least one Journey"),
      otherwise: yup.array().nullable(),
    }),
    trackComputerName: yup.bool().nullable(),
  }),
  leafVersion: yup
    .string()
    .nullable()
    .matches(/^24\.\d+\.\d+\.\d+$/, "Wrong leaf version format"),
  skipOnboarding: yup.bool(),
  canSetInteractionTime: yup.bool().nullable(),
  allowSilenceHi: yup.bool().nullable(),
  isCopilotGo: yup.bool().nullable(),
  udHoneycomb: yup.bool().nullable(),
  extraTab: yup.bool().nullable(),
  extraTabProps: yup.object().when("extraTab", {
    is: true,
    then: yup.object().shape({
      icon: yup.string().required("Icon is required"),
      title: yup.string().required("Title is required"),
      url: yup.string().required("URL is required"),
    }),
    otherwise: yup.object().nullable(),
  }),
  extraTab2: yup.bool().nullable(),
  extraTab2Props: yup.object().when("extraTab2", {
    is: true,
    then: yup.object().shape({
      icon: yup.string().required("Icon is required"),
      title: yup.string().required("Title is required"),
      url: yup.string().required("URL is required"),
    }),
    otherwise: yup.object().nullable(),
  }),
  dashHeaderTitle: yup.string().nullable(),
  displayJourneyProgress: yup.bool().nullable(),
  displayDairyTab: yup.bool().nullable(),
  displayJourneySelectionTab: yup.bool().nullable(),
  displayPromptOfFameTab: yup.bool().nullable(),
  displayHiTour: yup.bool().nullable(),
  displayHome: yup.bool().nullable(),
  dashboardAccess: yup.string().when("channel", {
    is: (value) => value === CHANNEL_EMBEDDED.value,
    then: yup.string().nullable().required(),
    otherwise: yup.string().nullable(),
  }),
});

const ConfigurationPageContainer = () => {
  const {
    fetchConfiguration,
    configuration,
    isConfigurationLoading,
    isJourneysLoading,
    fetchJourneys,
    journeys,
    updateConfiguration,
  } = useAppContext();

  const history = useHistory();
  const location = useLocation();

  const { cid } = useParams();
  const { state } = useLocation();

  const configurationEntity = state?.isNew
    ? {
        isNew: true,
        availableJourneys: [],
        name: "",
        cid: "",
        channel: "client",
        currentJourney: null,
        locale: null,
        homePageLocalized: {},
        homePageSetting: { mode: "default", forceForJourneyIds: [] },
        canSetInteractionTime: true,
        displayJourneyProgress: true,
        displayDairyTab: true,
        dashHeaderTitle: "hi platform",
        displayHiTour: true,
        displayJourneySelectionTab: true,
        displayPromptOfFameTab: false,
        hiWebBtnIcon: "",
        dashboardAccess: null,
        allowSilenceHi: false,
        isCopilotGo: false,
        udHoneycomb: false,
        extraTab: false,
        extraTab2: false,
        displayHome: true,
      }
    : configuration;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: configurationEntity && {
      colors: {
        primary: configurationEntity.colors?.primary ?? "#E6444E",
        secondary: configurationEntity.colors?.secondary ?? "#33A6A5",
      },
      extraTab: (configurationEntity.extraTab ?? false) === true,
      extraTabProps:
        configurationEntity.extraTab ?? false
          ? {
              icon: configurationEntity.extraTabProps?.icon ?? "",
              title: configurationEntity.extraTabProps?.title ?? "",
              url: configurationEntity.extraTabProps?.url ?? "",
            }
          : undefined,
      extraTab2: (configurationEntity.extraTab2 ?? false) === true,
      extraTab2Props:
        configurationEntity.extraTab2 ?? false
          ? {
              icon: configurationEntity.extraTab2Props?.icon ?? "",
              title: configurationEntity.extraTab2Props?.title ?? "",
              url: configurationEntity.extraTab2Props?.url ?? "",
            }
          : undefined,
      homePageLocalized: {},
      homePageSetting: {
        forceForJourneyIds: configurationEntity?.homePageSetting?.mode ?? [], // initialize; otherwise validation error does not highlight the field
        mode: configurationEntity?.homePageSetting?.mode ?? "default",
      },
      allowSilenceHi: (configurationEntity.allowSilenceHi ?? false) === true,
      isCopilotGo: (configurationEntity.isCopilotGo ?? false) === true,
      udHoneycomb: (configurationEntity.udHoneycomb ?? false) === true,
      canSetInteractionTime:
        (configurationEntity.canSetInteractionTime ?? true) === true,
      dashboardAccess: configurationEntity.dashboardAccess || null,
      displayJourneyProgress:
        (configurationEntity.displayJourneyProgress ?? true) === true,
      displayDairyTab: (configurationEntity.displayDairyTab ?? true) === true,
      displayHiTour: (configurationEntity.displayHiTour ?? true) === true,
      displayJourneySelectionTab:
        (configurationEntity.displayJourneySelectionTab ?? true) === true,
      displayPromptOfFameTab:
        (configurationEntity.displayPromptOfFameTab ?? false) === true,
      displayHome: (configurationEntity.displayHome ?? true) === true,
      ...configurationEntity,
    },
    onSubmit: async (values) => {
      await updateConfiguration({
        ...values,
        colors: {
          primary:
            values.colors?.primary === "#E6444E"
              ? null
              : values.colors?.primary,
          secondary:
            values.colors?.secondary === "#33A6A5"
              ? null
              : values.colors?.secondary,
        },
        ...(values.extraTab ? { extraTabProps: values.extraTabProps } : {}),
        ...(values.extraTab2 ? { extraTab2Props: values.extraTab2Props } : {}),
      });
      if (!values.isNew) {
        return;
      }

      history.replace({
        ...location,
        pathname: location.pathname.replace("new", values.cid),
        state: null,
      });
    },
    validationSchema: editSchema,
    validator: () => ({}),
  });

  useEffect(() => {
    if (!state?.isNew) {
      fetchConfiguration({ cid });
    }
    fetchJourneys();
  }, [cid]);

  if (isConfigurationLoading || isJourneysLoading) {
    return <Loader />;
  }

  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">
          Configuration is not found 😢
        </Typography>
      </Box>
    );
  }

  return <ConfigurationPage formik={formik} journeys={journeys} />;
};

export default ConfigurationPageContainer;
