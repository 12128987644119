import { useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import bytes from "bytes";

import * as yup from "yup";

import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Accordion, AccordionSummary } from "../shared/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import ListItemText from "@material-ui/core/ListItemText";
import Alert from "@material-ui/lab/Alert";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AddIcon from "@material-ui/icons/Add";

import { uploadFile, validateIframeUrl, uploadFiles } from "../../api";
import Timestamps from "../Timestamps";
import ImageUpload from "../ImageUpload";
import { getFieldProps, inputProps } from "../utils";
import InfoTooltip from "../InfoTooltip";
import FileUpload from "../shared/FileUpload";

import SemanticsSelect from "../shared/SemanticsSelect";
import SurveySelect from "../shared/SurveySelect";
const SelectSemanticMaterial = ({
  alreadySelectedFlowIds,
  formik,
  journeyId,
  locale,
}) => {
  // this will add to formik.values three mandatory fields: flowId, flowCompleteSemanticIds, flowCompleteType (cta-clicked or tip-reached)
  // flowId should not be already selected in other materials (preserve 1-1 relationship materials-flows)
  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <SemanticsSelect
          required
          filter={(semantic) =>
            semantic.isFirstInFlow &&
            (semantic.flowId === formik.values.flowId ||
              !alreadySelectedFlowIds.includes(semantic.flowId))
          }
          journeyId={journeyId}
          label="Message"
          locale={locale}
          primaryColumn="flowId"
          {...getFieldProps(formik, {
            name: "flowId",
          })}
          onChange={(value) => {
            formik.setFieldValue("flowId", value.flowId);
            formik.setFieldTouched("flowId", true);
            formik.setFieldValue("flowCompleteSemanticIds", []);
            formik.setFieldTouched("flowCompleteSemanticIds", true);
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          select
          InputLabelProps={{ shrink: true }}
          margin="none"
          variant="outlined"
          {...getFieldProps(formik, {
            name: "flowCompleteType",
          })}
          label="The material can be considered completed when *"
        >
          <MenuItem value="cta-clicked">{`CTA clicked`}</MenuItem>
          <MenuItem value="tip-reached">Tip reached</MenuItem>
        </TextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <SemanticsSelect
          fullWidth
          multiple
          required
          disableClearable={false}
          filter={(item) => item.flowId === formik.values.flowId}
          journeyId={journeyId}
          label="Select which tip(s) will complete the material"
          locale={locale}
          {...getFieldProps(formik, {
            disabled: !formik.values.flowId,
            helperText:
              !formik.values.flowId &&
              'Select message first in "Message" field',
            name: "flowCompleteSemanticIds",
          })}
          onChange={(values) => {
            formik.setFieldValue(
              "flowCompleteSemanticIds",
              values.map(({ id }) => id)
            );
            formik.setFieldTouched("flowCompleteSemanticIds", true);
          }}
        />
      </Grid>
    </Grid>
  );
};

const editSchema = yup.object().shape({
  ctaUrl: yup
    .string()
    .url()
    .when("type", {
      is: (type) => type === "WEBPAGE",
      then: yup
        .string()
        .url()
        .required()
        .when("shouldOpenInBrowser", {
          is: (shouldOpenInBrowser) => !shouldOpenInBrowser,
          then: yup
            .string()
            .url()
            .required()
            .test(
              "ctaUrl",
              'Your selected url does not allow other sites to embed it. Please change URL or select "Open in browser".',
              async (value) => {
                try {
                  if (!yup.string().url().isValidSync(value)) {
                    return true;
                  }

                  const { valid } = await validateIframeUrl({ url: value });

                  return valid;
                } catch (e) {
                  return false;
                }
              }
            ),
          otherwise: yup.string().url().required(),
        }),
      otherwise: yup.string().when("type", {
        is: (type) => type === "SEMANTIC" || type === "SURVEY",
        then: yup.string().nullable(),
        otherwise: yup.string().url().required(),
      }),
    }),
  flowId: yup
    .string()
    .nullable()
    .when("type", {
      is: (type) => type === "SEMANTIC",
      otherwise: yup.string().nullable(),
      then: yup.string().required("Message flow id is required"),
    }),
  flowCompleteSemanticIds: yup.array().when("type", {
    is: (type) => type === "SEMANTIC",
    then: yup
      .array()
      .of(yup.string().required())
      .min(1, "Select at least a tip to complete the material"),
    otherwise: yup.array().of(yup.string()).nullable(),
  }),
  flowCompleteType: yup.string().when("type", {
    is: (type) => type === "SEMANTIC",
    then: yup.string().oneOf(["cta-clicked", "tip-reached"]).required(),
    otherwise: yup.string().nullable(),
  }),
  image: yup.string(),
  name: yup.string().required(),
  shouldOpenInBrowser: yup.boolean().required(),
  surveyId: yup
    .string()
    .nullable()
    .when("type", {
      is: (type) => type === "SURVEY",
      otherwise: yup.string().nullable(),
      then: yup.string().required("Survey is required"),
    }),
  type: yup
    .string()
    .oneOf(["VIDEO", "IMAGE", "DOCUMENT", "WEBPAGE", "SEMANTIC", "SURVEY"])
    .required(),
  stepId: yup.string().required(),
});

const useEditMaterial = ({
  alreadySelectedFlowIds,
  material,
  locale,
  onUpdate,
  journey,
  journeys,
  isDisabled = false,
}) => {
  const {
    name,
    type,
    ctaUrl,
    shouldOpenInBrowser,
    flowId,
    flowCompleteSemanticIds,
    flowCompleteType,
    surveyId,
  } = material;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...material,
      ctaUrl: ctaUrl || "",
      name: name || "",
      shouldOpenInBrowser: shouldOpenInBrowser || false,
      type: type || "WEBPAGE",
      flowId: flowId || undefined,
      flowCompleteType: flowCompleteType || "tip-reached",
      flowCompleteSemanticIds: flowCompleteSemanticIds || [],
      surveyId: surveyId || undefined,
    },
    onSubmit: (values) => onUpdate(values),
    validationSchema: editSchema,
    validator: () => ({}),
  });

  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleUpload = useCallback(
    ({ url }) => {
      formik.setFieldValue("image", url);
      formik.setFieldTouched("image");
    },
    [formik]
  );

  const handleImageUpload = useCallback(
    ({ url }) => {
      formik.setFieldValue("ctaUrl", url);
      formik.setFieldTouched("ctaUrl");
    },
    [formik]
  );

  const handleFileUpload = useCallback(
    async (args) => {
      setIsFileUploading(true);

      const { url } = await uploadFile({
        ...args,
        customPathInfo: { journeyId: journey.id },
      });
      setIsFileUploading(false);
      await formik.setFieldValue("ctaUrl", url);
      await formik.setFieldTouched("ctaUrl");
    },
    [formik, journey.id]
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleHTMZipUpload = useCallback(
    async (args) => {
      setIsFileUploading(true);

      try {
        const { url } = await uploadFiles(args);
        await formik.setFieldValue("ctaUrl", url);
        await formik.setFieldTouched("ctaUrl");
      } catch (e) {
        enqueueSnackbar(`Upload Genially zip error: ${e.message}`, {
          variant: "error",
        });
      }

      setIsFileUploading(false);
    },
    [enqueueSnackbar, formik]
  );

  const handleTypeChange = useCallback(
    (e) => {
      formik.setFieldValue("type", e.target.value);
      formik.setFieldTouched("type");

      if (e.target.value === "WEBPAGE") {
        return;
      }

      formik.setFieldValue("shouldOpenInBrowser", false);
      formik.setFieldTouched("shouldOpenInBrowser");

      formik.setTouched({}, false); //reset validation
    },
    [formik]
  );

  const surveySelectorFilterFunction = (survey) => {
    return (
      !!survey?.distributionChannels?.link?.isEnabled && survey.type !== "hi"
    );
  };

  return {
    formik,
    ui: (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Timestamps
            copiedFromEntity="learning-material"
            copiedFromEntityId={material.copiedFromMaterialId}
            copiedFromJourneyId={material.copiedFromJourneyId}
            createdAt={material.createdAt}
            createdBy={material.createdBy}
            journeys={journeys}
            locale={locale}
            updatedAt={material.updatedAt}
            updatedBy={material.updatedBy}
          />
        </Grid>
        {!material.stepId && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              label="Step"
              variant="outlined"
              {...getFieldProps(formik, {
                disabled: isDisabled,
                name: "stepId",
              })}
            >
              {(journey?.steps ?? []).map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            {...(material.step && { autoFocus: true })}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={inputProps}
            label="Name"
            margin="none"
            placeholder="Material name..."
            variant="outlined"
            {...getFieldProps(formik, { disabled: isDisabled, name: "name" })}
          />
          {
            // Note: once all clients are on leaf > v.112, this alert won't be needed anymore
            getFieldProps(formik, { name: "name" }).value &&
              /[^A-Za-z0-9 ]+/g.test(
                getFieldProps(formik, { name: "name" }).value
              ) && (
                <Alert severity="warning">
                  Special characters different from standard alphabet, numbers
                  or spaces may not work when a tip CTA is linked to them.
                  Either remove them or simulate the tip and its CTA in your hi
                  client (not from content portal).
                </Alert>
              )
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography style={{ fontWeight: "bolder" }}>
                    Image
                  </Typography>
                </Grid>
                <Grid item>
                  <InfoTooltip
                    text="The picture uploaded will be resized to be with width 680px and height 383px. The picture will be automatically cropped"
                    title="Image Dimensions"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ImageUpload
                buttonProps={{ children: "Upload", fullWidth: false }}
                caption={
                  <Typography
                    color="textSecondary"
                    style={{ color: "#BFBFBF", fontStyle: "italic" }}
                    variant="caption"
                  >
                    The picture uploaded will be resized to be with width 680px
                    and height 383px. The picture will be automatically cropped.
                  </Typography>
                }
                customPathInfo={{ journeyId: journey.id }}
                disabled={formik.isSubmitting || isDisabled}
                errorMessage={
                  getFieldProps(formik, { name: "image" }).helperText
                }
                value={formik.values.image}
                onChange={handleUpload}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item alignItems="center" spacing={1} xs={12}>
          <Grid item>
            <Typography variant="h6">Learning Material content</Typography>
          </Grid>
          <Grid item>
            <InfoTooltip
              text="This is the Learning Material that will appear in the user Dashboard. You can upload .jpg, .png, and .pdf files. For videos, we suggest uploading them to an external platform (e.g., Vimeo) and add here the link. You can also add the link for any Learning Material that is available as a web page: be sure it has “https://” at the beginning of the URL, otherwise it won’t be uploaded."
              title="How to upload a file correctly?"
            />
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={4}>
            {formik.values.type === "SEMANTIC" && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  This configuration is not supported in teams app.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                disabled={isDisabled}
                label="File type"
                margin="none"
                name="type"
                value={formik.values.type}
                variant="outlined"
                onChange={handleTypeChange}
              >
                <MenuItem value="VIDEO">Video</MenuItem>
                <MenuItem value="IMAGE">Image (.jpg, .png)</MenuItem>
                <MenuItem value="DOCUMENT">Document (.pdf)</MenuItem>
                <MenuItem value="WEBPAGE">Web Page</MenuItem>
                <MenuItem value="SEMANTIC">Tip</MenuItem>
                <MenuItem value="SURVEY">Survey</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              {journey && formik.values.type === "SEMANTIC" && (
                <SelectSemanticMaterial
                  alreadySelectedFlowIds={alreadySelectedFlowIds}
                  formik={formik}
                  journeyId={journey.id}
                  locale={locale}
                ></SelectSemanticMaterial>
              )}
              {journey && formik.values.type === "SURVEY" && (
                <SurveySelect
                  filterfunction={surveySelectorFilterFunction}
                  formik={formik}
                  journeyId={journey.id}
                ></SurveySelect>
              )}
              {formik.values.type !== "SEMANTIC" &&
                formik.values.type !== "SURVEY" && (
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={inputProps}
                    label="Learning Material Link"
                    margin="none"
                    placeholder="https://"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      disabled: isDisabled,
                      name: "ctaUrl",
                    })}
                  />
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {formik.values.type === "WEBPAGE" && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.shouldOpenInBrowser}
                      color="primary"
                      name="shouldOpenInBrowser"
                      onChange={formik.handleChange}
                    />
                  }
                  label="Open in browser"
                />
              </Grid>
              {/* Genially files are now uploaded using zip*/}
              <Grid item xs={12}>
                <FileUpload
                  accept=".zip"
                  onChange={(args) =>
                    handleHTMZipUpload({
                      ...args,
                      customPathInfo: { journeyId: journey.id },
                    })
                  }
                >
                  {({ upload, error }) => (
                    <>
                      <Button
                        color="primary"
                        disabled={isFileUploading || isDisabled}
                        endIcon={
                          isFileUploading && <CircularProgress size={25} />
                        }
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={upload}
                      >
                        Upload HTML zip
                      </Button>
                      {error && (
                        <div>
                          <Typography color="error" variant="caption">
                            {error}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                </FileUpload>
              </Grid>
            </Grid>
          )}

          {["DOCUMENT", "IMAGE"].includes(formik.values.type) && (
            <Grid container alignContent="flex-start" spacing={2}>
              <Grid item>
                {formik.values.type === "DOCUMENT" && (
                  <FileUpload
                    accept=".pdf"
                    disabled={isDisabled}
                    maxSize={bytes("10 MB")}
                    readAs="DataURL"
                    onChange={handleFileUpload}
                  >
                    {({ upload, error, value }) => (
                      <>
                        <Button
                          color="primary"
                          disabled={isFileUploading}
                          endIcon={
                            isFileUploading && <CircularProgress size={25} />
                          }
                          startIcon={<AddIcon />}
                          variant="outlined"
                          onClick={upload}
                        >
                          Upload
                        </Button>
                        {error && (
                          <div>
                            <Typography color="error" variant="caption">
                              {error}
                            </Typography>
                          </div>
                        )}
                        {value && (
                          <Box mt={2}>
                            <Typography variant="subtitle2">
                              <a
                                href={
                                  isFileUploading
                                    ? value.body
                                    : formik.values.ctaUrl
                                }
                                rel="noreferrer noopener"
                                target="_blank"
                                {...(isFileUploading
                                  ? { download: value.name }
                                  : {})}
                              >
                                <Box alignItems="center" display="flex">
                                  <Box mr={1}>{value.name}</Box>
                                  <OpenInNewIcon fontSize="small" />
                                </Box>
                              </a>
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </FileUpload>
                )}
                {formik.values.type === "IMAGE" && (
                  <ImageUpload
                    customPathInfo={{ journeyId: journey.id }}
                    disabled={isDisabled}
                    value={formik.values.ctaUrl}
                    onChange={handleImageUpload}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>

        {formik.values.type === "VIDEO" && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">FAQ</Typography>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1">
                    How do I get the proper URL for a video from Youtube or
                    Vimeo?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper>
                    <Box p={2}>
                      <ul>
                        <li>
                          <Typography variant="body1">
                            Either in Youtube or Vimeo, in the page where the
                            video is opened, click on “Share”
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            Click the “Embed” (”Incorpora” in italian) option
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            copy what’s inside quotes of src
                          </Typography>
                          <Box pb={2} pt={2}>
                            <img
                              alt=""
                              src="/materials/faq/embedsrc.png"
                              style={{ maxWidth: "100%" }}
                            />
                          </Box>
                        </li>
                        <li>
                          <Typography variant="body1">
                            If you want to add autoplay
                            <ul>
                              <li>
                                If the URL copied already contains a ? → add to
                                that URL &autoplay=1
                              </li>
                              <li>Otherwise → add to that URL ?autoplay=1</li>
                            </ul>
                          </Typography>
                        </li>
                      </ul>
                    </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>
    ),
  };
};

export default useEditMaterial;
