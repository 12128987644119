import React from "react";
import "@mdxeditor/editor/style.css";
import "./MarkdownEditorV2.css";
import { debounce } from "lodash";
import { MDXEditor } from "@mdxeditor/editor/MDXEditor";

import { toolbarPlugin } from "@mdxeditor/editor/plugins/toolbar";
import { listsPlugin } from "@mdxeditor/editor/plugins/lists";
import { quotePlugin } from "@mdxeditor/editor/plugins/quote";
import { linkPlugin } from "@mdxeditor/editor/plugins/link";
import { linkDialogPlugin } from "@mdxeditor/editor/plugins/link-dialog";
// import { markdownShortcutPlugin } from "@mdxeditor/editor/plugins/markdown-shortcut";
import { UndoRedo } from "@mdxeditor/editor/plugins/toolbar/components/UndoRedo";
import { BoldItalicUnderlineToggles } from "@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles";
import { CreateLink } from "@mdxeditor/editor/plugins/toolbar/components/CreateLink";
// import { CodeToggle } from "@mdxeditor/editor/plugins/toolbar/components/CodeToggle";
import { BlockTypeSelect } from "@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect";
import { ListsToggle } from "@mdxeditor/editor/plugins/toolbar/components/ListsToggle";
import { FormControl, FormGroup, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";

const MarkdownEditorV2 = ({
  value,
  placeHolder,
  onChange,
  error,
  helperText,
}) => {
  const debouncedOnChange = debounce(onChange, 500);

  return (
    <FormControl fullWidth component="fieldset" error={error}>
      <Typography color={error ? "error" : "textPrimary"} variant="subtitle2">
        {placeHolder}
      </Typography>
      <FormGroup>
        <Box minHeight={93}>
          <MDXEditor
            contentEditableClassName={"custom-mdx-editor"}
            markdown={value ?? ""}
            plugins={[
              listsPlugin(),
              linkPlugin(),
              quotePlugin(),
              linkDialogPlugin(),
              // markdownShortcutPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <BoldItalicUnderlineToggles />
                    <UndoRedo />
                    <CreateLink />
                    <ListsToggle />
                    {/* <CodeToggle /> */}
                    <BlockTypeSelect />
                  </>
                ),
              }),
            ]}
            onChange={debouncedOnChange}
          />
        </Box>
      </FormGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MarkdownEditorV2;
